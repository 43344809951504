import React from 'react';
import useAppStore from '@/hooks/use-app-store';
import { Share2 } from 'lucide-react';
import { useSession } from 'next-auth/react';
import { usePathname, useRouter } from 'next/navigation';

const ShareButton = ({
  count = 500,
  disabled = false,
  path,
  hideCount,
  title = 'Share this post',
}: {
  hideCount?: boolean;
  path?: string;
  disabled?: boolean;
  count?: number;
  title?: string;
}) => {
  const openShareModal = useAppStore((state: any) => state.openShareModal);
  const session = useSession();
  const router = useRouter();
  const pathname = usePathname();

  return (
    <div className="flex gap-1 items-center text-sm">
      {session?.status === 'unauthenticated' ? (
        <button
          className="flex cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            router.push(`${pathname}?ref=signin`);
          }}
        >
          <Share2 className="text-gray-brand5" size={16} />
        </button>
      ) : (
        <button
          className="h-fit w-fit flex p-0"
          onClick={() => openShareModal({ title, path })}
          disabled={disabled}
        >
          <Share2 className="text-gray-brand5" size={16} />
        </button>
      )}

      {!hideCount && <span>{count}</span>}
    </div>
  );
};

export default ShareButton;
